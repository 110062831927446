import { FC } from "react";
import { Tag, Descriptions } from "antd";
import { dateFormatter } from "src/shared/config/constants";
import Currency from "src/shared/components/currency/Currency";
import { useMediaQuery } from "react-responsive";
import { isTriumphEmployee } from "src/helpers";
import { PhoneOutlined } from "@ant-design/icons";
import UserStatistics from "./userStatistics";

const UserInformation: FC<{
  user: AppUser & AppUserPublic;
  refferal: Referral | undefined;
  userStats?: UserStatisticsAmountsResponse;
}> = ({ user, userStats }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  const lg = useMediaQuery({ maxWidth: 1600, minWidth: 800 });
  const xl = useMediaQuery({ minWidth: 1600 });

  const columnSize = () => {
    if (xl) return 3;
    if (lg) return 2;
    if (isMobile) return 1;
    return 3;
  };

  const getUserOriginalName = () => {
    if (user.firstName == null || user.lastName == null) return user.name;
    return user.firstName + " " + user.lastName;
  };
  return (
    <>
      <UserStatistics statsData={userStats} />
      <Descriptions
        layout={!isMobile ? "horizontal" : "vertical"}
        column={columnSize()}
        bordered
        title="Personal Details"
        className="pb-2"
      >
        <Descriptions.Item label="User Name">{user.username}</Descriptions.Item>
        {isTriumphEmployee() && (
          <Descriptions.Item label="Name">
            {getUserOriginalName()}
          </Descriptions.Item>
        )}
        <Descriptions.Item label="UID">{user.uid}</Descriptions.Item>
        <Descriptions.Item label="Joining Date">
          {dateFormatter(user.createdAt)}
        </Descriptions.Item>
        <Descriptions.Item label="Last Updated">
          {dateFormatter(user.updatedAt)}
        </Descriptions.Item>

        <Descriptions.Item label="Status">
          <Tag color={user.banned ? "red" : "green"}>
            {user.banned ? "Banned" : "Not Banned"}
          </Tag>
        </Descriptions.Item>

        <Descriptions.Item label="KYC Status">
          {user.kycStatus ? "Yes" : "No"}
        </Descriptions.Item>
        <Descriptions.Item label="Is Deleted">
          {user.isDeleted ? "Yes" : "No"}
        </Descriptions.Item>
        <Descriptions.Item label="Is Anon">
          {user.isAnon ? "Yes" : "No"}
        </Descriptions.Item>

        {isTriumphEmployee() && (
          <Descriptions.Item label="Phone Number">
            {user.phoneNumber}
            {user.phoneNumber && (
              <a href={`tel:${user.phoneNumber}`}>
                <PhoneOutlined />
              </a>
            )}
          </Descriptions.Item>
        )}
      </Descriptions>

      <Descriptions
        title="Balance"
        layout={!isMobile ? "horizontal" : "vertical"}
        column={columnSize()}
        bordered
        className="mb-2"
      >
        <Descriptions.Item label="Balance">
          <Currency
            currency="cents-to-dollar"
            prefix="$"
            value={user.balance}
          />
        </Descriptions.Item>

        <Descriptions.Item label="Bonus Cash Balance">
          <Currency
            currency="cents-to-dollar"
            prefix="$"
            value={user.bonusCashBalance}
          />
        </Descriptions.Item>

        <Descriptions.Item label="Withdrawable Balance">
          <Currency
            currency="cents-to-dollar"
            prefix="$"
            value={user.withdrawableBalance}
          />
        </Descriptions.Item>

        <Descriptions.Item label="Gem Balance">
          {user.gemBalance}
        </Descriptions.Item>
        {/* <Descriptions.Item label="Withdrawl Limit">
            <Currency
              currency="cents-to-dollar"
              prefix="$"
              value={user.withdrawalLimit}
            />
          </Descriptions.Item> */}
      </Descriptions>
    </>
  );
};
export default UserInformation;
