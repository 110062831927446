const BalanceTransactionTypeDailyReward = "daily-reward";
const BalanceTransactionTypeDailyRewardRedeposit = "daily-reward-redeposit";
const BalanceTransactionTypeMoonOffer = "moon-offer";
const BalanceTransactionTypeDeposit = "deposit";
const BalanceTransactionTypeStartGroupTournament = "start-group-tournament";
const BalanceTransactionTypeFinishGroupTournament = "finish-group-tournament";
const BalanceTransactionTypeReverseStartGroupTournament =
  "reverse-start-group-tournament";
const BalanceTransactionTypeStartBlitz = "start-blitz";
const BalanceTransactionTypeFinishBlitz = "finish-blitz";
const BalanceTransactionTypeReverseStartBlitz = "reverse-start-blitz";
const BalanceTransactionTypeChallengeEntry = "challenge-entry";
const BalanceTransactionTypeChallengePrize = "challenge-prize";
const BalanceTransactionTypeChallengeRefund = "reverse-challenge-entry";
const BalanceTransactionTypeReferral = "referral";
const BalanceTransactionTypeProgressiveReferrals = "progressive-referrals";
const BalanceTransactionTypeLeaguePromotion = "league-promotion";
const BalanceTransactionTypeWithdrawal = "withdrawal";
const BalanceTransactionTypeWithdrawalRedeposit = "withdrawal-redeposit";
const BalanceTransactionTypeInitialCredit = "initial-credit";
const BalanceTransactionTypeCreditOnBoarding = "onboarding-credit";
const BalanceTransactionTypeReviewReward = "review-reward";
const BalanceTransactionTypeFreeOffer = "free-offer";
const BalanceTransactionTypeTriumphDeposit = "triumph-deposit";
const BalanceTransactionTypeAdReward = "ad-reward";
const BalanceTransactionTypeAccountBanDecrement = "account-ban-decrement";

export const AllBalanceTrx = [
  BalanceTransactionTypeDailyReward,
  BalanceTransactionTypeDailyRewardRedeposit,
  BalanceTransactionTypeMoonOffer,
  BalanceTransactionTypeDeposit,
  BalanceTransactionTypeStartGroupTournament,
  BalanceTransactionTypeFinishGroupTournament,
  BalanceTransactionTypeReverseStartGroupTournament,
  BalanceTransactionTypeStartBlitz,
  BalanceTransactionTypeFinishBlitz,
  BalanceTransactionTypeReverseStartBlitz,
  BalanceTransactionTypeChallengeEntry,
  BalanceTransactionTypeChallengePrize,
  BalanceTransactionTypeChallengeRefund,
  BalanceTransactionTypeReferral,
  BalanceTransactionTypeProgressiveReferrals,
  BalanceTransactionTypeLeaguePromotion,
  BalanceTransactionTypeWithdrawal,
  BalanceTransactionTypeWithdrawalRedeposit,
  BalanceTransactionTypeInitialCredit,
  BalanceTransactionTypeCreditOnBoarding,
  BalanceTransactionTypeReviewReward,
  BalanceTransactionTypeFreeOffer,
  BalanceTransactionTypeTriumphDeposit,
  BalanceTransactionTypeAdReward,
  BalanceTransactionTypeAccountBanDecrement,
];
