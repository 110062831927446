import { collection, collectionGroup } from "firebase/firestore";
import { db, generateFirestorePath } from "src/helpers";
import { converter } from "./converters";
/** type imports */
import type {
  CollectionReference,
  DocumentReference,
  Query,
} from "firebase/firestore";

/**
 * ======= `FIRESTORE COLLECTION NAMES` =======
 *
 * `NOTE:` When adding/removing/updating make sure
 * variable names match db collection names
 *
 */

export const colNames = {
  /* Top level col names */
  adminUsers: "adminUsers",
  appUsers: "appUsers",
  appUsersPublic: "appUsersPublic",
  gameStatistics: "gameStatistics",
  games: "games",
  internalPhoneNumbers: "internalPhoneNumbers",
  masterContract: "masterContract",
  offers: "offers",
  orgStatistics: "orgStatistics",
  triumphStatistics: "triumphStatistics",
  organizations: "organizations",
  statistics: "statistics",
  supportTickets: "supportTickets",
  //   influencers: "influencers",
  //   appUserAbuse: "appUserAbuse",
  //   referrals: "referrals",

  /* Sub Collections */
  blitzTournamentsV2: "blitzTournamentsV2",
  blitzTournaments: "blitzTournaments",
  avgScoreShards: "avgScoreShards",
  groupTournaments: "groupTournaments",
  groupTournamentsV3: "groupTournamentsV3",
  gameStates: "gameStates",
  payoutHistory: "payoutHistory",
  offerStates: "offerStates",
  blitzTournamentDefinitions: "blitzTournamentDefinitions",
  groupTournamentDefinitions: "groupTournamentDefinitions",
  depositDefinitions: "depositDefinitions",
  memberInvites: "memberInvites",
  balanceTransactions: "balanceTransactions",
  balanceTransactionsV2: "balanceTransactionsV2",
  contracts: "contracts",
  orgBalanceShards: "orgBalanceShards",
  orgTransfers: "orgTransfers",
  orgBalanceTransactions: "orgBalanceTransactions",
  blitzData: "blitzData",
  blitzBuckets: "blitzBuckets",
  colors: "colors",
  hourly: "hourly",
  daily: "daily",
  weekly: "weekly",
  monthly: "monthly",
  yearly: "yearly",
  //   abuseEvents: "abuseEvents",
  pushNotifications: "pushNotifications",
  configureOffers: "offers",
  banners: "banners",
  referrals: "referrals",
} as const;

type ColNames = keyof typeof colNames;

type Cols = typeof colNames;

// =================-----------------------------=====================
// =================        GET COLLECTION       =====================
// =================-----------------------------=====================

/* Fn Overloads - Root Collections */

/**
 * Fetch `adminUsers`
 *
 * @param {string} colName firestore collection name
 * @return {CollectionReference<AdminUser>} `CollectionReference<AdminUser>` instance
 */
export function getCollection(
  colName: Cols["adminUsers"]
): CollectionReference<AdminUser>;

/**
 * Fetch `appUsers`
 *
 * @param {string} colName firestore collection name
 * @return {CollectionReference<AppUser>} `CollectionReference<AppUser>` instance
 */
export function getCollection(
  colName: Cols["appUsers"]
): CollectionReference<AppUser>;

/**
 * Fetch `appUsersPublic`
 *
 * @param {string} colName firestore collection name
 * @return {CollectionReference<AppUserPublic>} `CollectionReference<AppUserPublic>` instance
 */
export function getCollection(
  colName: Cols["appUsersPublic"]
): CollectionReference<AppUserPublic>;

/**
 * Fetch `games`
 *
 * @param {string} colName firestore collection name
 * @return {CollectionReference<Game>} `CollectionReference<Game>` instance
 */
export function getCollection(
  colName: Cols["games"]
): CollectionReference<Game>;

/**
 * Fetch `internalPhoneNumbers`
 *
 * @param {string} colName firestore collection name
 * @return {CollectionReference<InternalPhoneNumber>} `CollectionReference<InternalPhoneNumber>` instance
 */
export function getCollection(
  colName: Cols["internalPhoneNumbers"]
): CollectionReference<InternalPhoneNumber>;

/**
 * Fetch `masterContract`
 *
 * @param {string} colName firestore collection name
 * @return {CollectionReference<MasterContract>} `CollectionReference<MasterContract>` instance
 */
export function getCollection(
  colName: Cols["masterContract"]
): CollectionReference<MasterContract>;

/**
 * Fetch `offers`
 *
 * @param {string} colName firestore collection name
 * @return {CollectionReference<Offer>} `CollectionReference<Offer>` instance
 */
export function getCollection(
  colName: Cols["offers"]
): CollectionReference<Offer>;

/**
 * Fetch `organizations`
 *
 * @param {string} colName firestore collection name
 * @return {CollectionReference<Organization>} `CollectionReference<Organization>` instance
 */
export function getCollection(
  colName: Cols["organizations"]
): CollectionReference<Organization>;

/**
 * Fetch `supportTickets`
 *
 * @param {string} colName firestore collection name
 * @return {CollectionReference<SupportTicket>} `CollectionReference<SupportTicket>` instance
 */
export function getCollection(
  colName: Cols["supportTickets"]
): CollectionReference<SupportTicket>;

/* Fn Overloads - Sub Collections */

/**
 * Fetch `Blitz Tournaments V2`
 *
 * @param {string} colName firestore collection name
 * @param {DocumentReference<Game>} parent expected: `DocumentReference<Game>`
 * @return {CollectionReference<BlitzTournamentV2>} `CollectionReference<BlitzTournamentV2>` instance
 */
export function getCollection(
  colName: Cols["blitzTournamentsV2"],
  parent: DocumentReference<Game>
): CollectionReference<BlitzTournamentV2>;

/**
 * Fetch `Blitz Tournaments V1`
 *
 * @param {string} colName firestore collection name
 * @param {DocumentReference<Game>} parent expected: `DocumentReference<Game>`
 * @return {CollectionReference<BlitzTournamentV1>} `CollectionReference<BlitzTournamentV1>` instance
 */
export function getCollection(
  colName: Cols["blitzTournaments"],
  parent: DocumentReference<Game>
): CollectionReference<BlitzTournamentV1>;

/**
 * Fetch `avgScoreShards`
 *
 * @param {string} colName firestore collection name
 * @param {DocumentReference<Game>} parent expected: `DocumentReference<Game>`
 * @return {CollectionReference<AvgScoreShard>} `CollectionReference<AvgScoreShard>` instance
 */
export function getCollection(
  colName: Cols["avgScoreShards"],
  parent: DocumentReference<Game>
): CollectionReference<AvgScoreShard>;

/**
 * Fetch `groupTournaments`
 *
 * @param {string} colName firestore collection name
 * @param {DocumentReference<AppUser>} parent expected: `DocumentReference<AppUser>`
 * @return {CollectionReference<AsyncGroupTournamentV1>} `CollectionReference<AsyncGroupTournamentV1>` instance
 */
export function getCollection(
  colName: Cols["groupTournaments"],
  parent: DocumentReference<Game>
): CollectionReference<AsyncGroupTournamentV1>;

/**
 * Fetch `groupTournamentsV3`
 *
 * @param {string} colName firestore collection name
 * @param {DocumentReference<Game>} parent expected: `DocumentReference<Game>`
 * @return {CollectionReference<AsyncGroupTournamentV3>} `CollectionReference<AsyncGroupTournamentV3>` instance
 */
export function getCollection(
  colName: Cols["groupTournamentsV3"],
  parent: DocumentReference<Game>
): CollectionReference<AsyncGroupTournamentV3>;

/**
 * Fetch `gameStates`
 *
 * @param {string} colName firestore collection name
 * @param {DocumentReference<AppUser>} parent expected: `DocumentReference<AppUser>`
 * @return {CollectionReference<UserGameState>} `CollectionReference<UserGameState>` instance
 */
export function getCollection(
  colName: Cols["gameStates"],
  parent: DocumentReference<AppUser>
): CollectionReference<UserGameState>;

/**
 * Fetch `offerStates`
 *
 * @param {string} colName firestore collection name
 * @param {DocumentReference<AppUser>} parent expected: `DocumentReference<AppUser>`
 * @return {CollectionReference<UserOffer>} `CollectionReference<UserOffer>` instance
 */
export function getCollection(
  colName: Cols["offerStates"],
  parent: DocumentReference<AppUser>
): CollectionReference<UserOffer>;

/**
 * Fetch `payoutHistory`
 *
 * @param {string} colName firestore collection name
 * @param {DocumentReference<AppUser>} parent expected: `DocumentReference<AppUser>`
 * @return {CollectionReference<PayoutHistory>} `CollectionReference<PayoutHistory>` instance
 */
export function getCollection(
  colName: Cols["payoutHistory"],
  parent: DocumentReference<AppUser>
): CollectionReference<PayoutHistory>;

/**
 * Fetch `balanceTransactions`
 *
 * @param {string} colName firestore collection name
 * @param {DocumentReference<AppUser>} parent expected: `DocumentReference<AppUser>`
 * @return {CollectionReference<AppUser>} `CollectionReference<BalanceTransaction>` instance
 */
export function getCollection(
  colName: Cols["balanceTransactions"],
  parent: DocumentReference<AppUser>
): CollectionReference<BalanceTransaction>;

/**
 * Fetch `balanceTransactionsV2`
 *
 * @param {string} colName firestore collection name
 * @param {DocumentReference<AppUser>} parent expected: `DocumentReference<AppUser>`
 * @return {CollectionReference<AppUser>} `CollectionReference<balanceTransactionsV2>` instance
 */
export function getCollection(
  colName: Cols["balanceTransactionsV2"],
  parent: DocumentReference<AppUser>
): CollectionReference<BalanceTransactionV2>;

/**
 * Fetch `contracts`
 *
 * @param {string} colName firestore collection name
 * @param {DocumentReference<Organization>} parent expected: `DocumentReference<Organization>`
 * @return {CollectionReference<Contract>} `CollectionReference<Contract>` instance
 */
export function getCollection(
  colName: Cols["contracts"],
  parent: DocumentReference<Organization>
): CollectionReference<Contract>;

/**
 * Fetch `orgBalanceShards`
 *
 * @param {string} colName firestore collection name
 * @param {DocumentReference<Organization>} parent expected: `DocumentReference<Organization>`
 * @return {CollectionReference<OrgBalanceShard>} `CollectionReference<OrgBalanceShard>` instance
 */
export function getCollection(
  colName: Cols["orgBalanceShards"],
  parent: DocumentReference<Organization>
): CollectionReference<OrgBalanceShard>;

/**
 * Fetch `orgBalanceTransactions`
 *
 * @param {string} colName firestore collection name
 * @param {DocumentReference<Organization>} parent expected: `DocumentReference<Organization>`
 * @return {CollectionReference<OrgBalanceTransaction>} `CollectionReference<OrgBalanceTransaction>` instance
 */
export function getCollection(
  colName: Cols["orgBalanceTransactions"],
  parent: DocumentReference<Organization>
): CollectionReference<OrgBalanceTransaction>;

/**
 * Fetch `orgTransfers`
 *
 * @param {string} colName firestore collection name
 * @param {DocumentReference<Organization>} parent expected: `DocumentReference<Organization>`
 * @return {CollectionReference<OrgDwollaTransfer>} `CollectionReference<OrgDwollaTransfer>` instance
 */
export function getCollection(
  colName: Cols["orgTransfers"],
  parent: DocumentReference<Organization>
): CollectionReference<OrgDwollaTransfer>;

/**
 * Fetch `memberInvites`
 *
 * @param {string} colName firestore collection name
 * @param {DocumentReference<Organization>} parent expected: `DocumentReference<Organization>`
 * @return {CollectionReference<MemberInvite>} `CollectionReference<MemberInvite>` instance
 */
export function getCollection(
  colName: Cols["memberInvites"],
  parent: DocumentReference<Organization>
): CollectionReference<MemberInvite>;

/**
 * Fetch `blitzTournamentDefinitions`
 *
 * @param {string} colName firestore collection name
 * @param {DocumentReference<Game>} parent expected: `DocumentReference<Game>`
 * @return {CollectionReference<BlitzDefinitionV2>} `CollectionReference<BlitzDefinitionV2>` instance
 */
export function getCollection(
  colName: Cols["blitzTournamentDefinitions"],
  parent: DocumentReference<Game>
): CollectionReference<BlitzDefinitionV2>;

/**
 * Fetch `groupTournamentDefinitions`
 *
 * @param {string} colName firestore collection name
 * @param {DocumentReference<Game>} parent expected: `DocumentReference<Game>`
 * @return {CollectionReference<TournamentGroupConfig>} `CollectionReference<TournamentGroupConfig>` instance
 */
export function getCollection(
  colName: Cols["groupTournamentDefinitions"],
  parent: DocumentReference<Game>
): CollectionReference<TournamentGroupConfig>;

/**
 * Fetch `depositDefinitions`
 *
 * @param {string} colName firestore collection name
 * @param {DocumentReference<Game>} parent expected: `DocumentReference<Game>`
 * @return {CollectionReference<DepositDefinition>} `CollectionReference<DepositDefinition>` instance
 */
export function getCollection(
  colName: Cols["depositDefinitions"],
  parent: DocumentReference<Game>
): CollectionReference<DepositDefinition>;

/**
 * Fetch `blitzData`
 *
 * @param {string} colName firestore collection name
 * @param {DocumentReference<Game>} parent expected: `DocumentReference<Game>`
 * @return {CollectionReference<BlitzData>} `CollectionReference<BlitzData>` instance
 */
export function getCollection(
  colName: Cols["blitzData"],
  parent: DocumentReference<Game>
): CollectionReference<BlitzData>;

/**
 * Fetch `colors`
 *
 * @param {string} colName firestore collection name
 * @param {DocumentReference<Game>} parent expected: `DocumentReference<Game>`
 * @return {CollectionReference<ColorConfig>} `CollectionReference<ColorConfig>` instance
 */
export function getCollection(
  colName: Cols["colors"],
  parent: DocumentReference<Game>
): CollectionReference<ColorConfig>;

/**
 * Fetch `blitzBuckets`
 *
 * @param {string} colName firestore collection name
 * @param {DocumentReference<Game>} parent expected: `DocumentReference<Game>`
 * @return {CollectionReference<BlitzBucket>} `CollectionReference<BlitzBucket>` instance
 */
export function getCollection(
  colName: Cols["blitzBuckets"],
  parent: DocumentReference<Game>
): CollectionReference<BlitzBucket>;

/**
 * Fetch `pushNotifications`
 *
 * @param {string} colName firestore collection name
 * @param {DocumentReference<Game>} parent expected: `DocumentReference<Game>`
 * @return {CollectionReference<PushNotification>} `CollectionReference<PushNotification>` instance
 */
export function getCollection(
  colName: Cols["pushNotifications"],
  parent: DocumentReference<Game>
): CollectionReference<PushNotification>;

/**
 * Fetch `configureOffers`
 *
 * @param {string} colName firestore collection name
 * @param {DocumentReference<Game>} parent expected: `DocumentReference<Game>`
 * @return {CollectionReference<ConfigureOffer>} `CollectionReference<configureOffers>` instance
 */
export function getCollection(
  colName: Cols["configureOffers"],
  parent: DocumentReference<Game>
): CollectionReference<ConfigureOffer>;

/**
 * Fetch `banners`
 *
 * @param {string} colName firestore collection name
 * @param {DocumentReference<Game>} parent expected: `DocumentReference<Game>`
 * @return {CollectionReference<Banner>} `CollectionReference<Banner>` instance
 */
export function getCollection(
  colName: Cols["banners"],
  parent: DocumentReference<Game>
): CollectionReference<Banner>;

/**
 * Fetch `referrals`
 *
 * @param {string} colName firestore collection name
 * @return {CollectionReference<Referral>} `CollectionReference<Referral>` instance
 */
export function getCollection(
  colName: Cols["referrals"]
): CollectionReference<Referral>;

/*  END Overloads */

/**
 * Utility function to retrieve firestore collections in a TYPE-SAFE way
 *
 * @param {string} colName name of the Firestore collection
 *
 * @param {DocumentReference} parent Firestore DocRef of the collection's parent document
 *
 * @return {CollectionReference} firestore collection reference corresponding to the `colName`
 */
export function getCollection(
  colName: ColNames,
  parent?: DocumentReference
): CollectionReference {
  let colRef: CollectionReference;

  if (parent) {
    colRef = collection(parent, generateFirestorePath(colName));
  } else {
    colRef = collection(db, generateFirestorePath(colName));
  }

  const {
    adminUsers,
    appUsers,
    appUsersPublic,
    games,
    gameStates,
    internalPhoneNumbers,
    masterContract,
    offers,
    supportTickets,
    avgScoreShards,
    groupTournaments,
    groupTournamentsV3,
    offerStates,
    blitzTournamentsV2,
    blitzTournaments,
    organizations,
    balanceTransactions,
    balanceTransactionsV2,
    contracts,
    orgBalanceShards,
    orgBalanceTransactions,
    orgTransfers,
    blitzData,
    blitzBuckets,
    colors,
    memberInvites,
    payoutHistory,
    blitzTournamentDefinitions,
    groupTournamentDefinitions,
    depositDefinitions,
    pushNotifications,
    configureOffers,
    banners,
    referrals,
  } = colNames;

  switch (colName) {
    /* Root Collections */
    case adminUsers:
      return colRef.withConverter(converter<AdminUser>());
    case appUsers:
      return colRef.withConverter(converter<AppUser>());
    case appUsersPublic:
      return colRef.withConverter(converter<AppUserPublic>());
    case games:
      return colRef.withConverter(converter<Game>());
    case internalPhoneNumbers:
      return colRef.withConverter(converter<InternalPhoneNumber>());
    case masterContract:
      return colRef.withConverter(converter<MasterContract>());
    case offers:
      return colRef.withConverter(converter<Offer>());
    case organizations:
      return colRef.withConverter(converter<Organization>());
    case supportTickets:
      return colRef.withConverter(converter<SupportTicket>());
    // case appUserAbuse:
    //   return colRef.withConverter(converter<UserState>());
    // case influencers:
    //   return colRef.withConverter(converter<Influencer>());
    // case referrals:
    //   return colRef.withConverter(converter<InfluencerReferral>());

    /* Sub Collections */
    case blitzTournamentsV2: {
      return colRef.withConverter(converter<BlitzTournamentV2>());
    }
    case blitzTournaments: {
      return colRef.withConverter(converter<BlitzTournamentV1>());
    }
    case avgScoreShards: {
      return colRef.withConverter(converter<AvgScoreData>());
    }
    case groupTournaments: {
      return colRef.withConverter(converter<AsyncGroupTournamentV1>());
    }
    case groupTournamentsV3: {
      return colRef.withConverter(converter<AsyncGroupTournamentV3>());
    }
    case blitzData: {
      return colRef.withConverter(converter<BlitzData>());
    }
    case blitzBuckets: {
      return colRef.withConverter(converter<BlitzBucket>());
    }
    case colors: {
      return colRef.withConverter(converter<ColorConfig>());
    }
    case gameStates: {
      return colRef.withConverter(converter<UserGameState>());
    }
    case offerStates: {
      return colRef.withConverter(converter<UserOffer>());
    }
    case payoutHistory: {
      return colRef.withConverter(converter<PayoutHistory>());
    }
    case balanceTransactions: {
      return colRef.withConverter(converter<BalanceTransaction>());
    }
    case balanceTransactionsV2: {
      return colRef.withConverter(converter<BalanceTransactionV2>());
    }
    case memberInvites: {
      return colRef.withConverter(converter<MemberInvite>());
    }
    case depositDefinitions: {
      return colRef.withConverter(converter<DepositDefinition>());
    }
    case blitzTournamentDefinitions: {
      return colRef.withConverter(converter<BlitzDefinitionV2>());
    }
    case groupTournamentDefinitions: {
      return colRef.withConverter(converter<TournamentGroupConfig>());
    }
    case contracts: {
      return colRef.withConverter(converter<Contract>());
    }
    case orgBalanceShards: {
      return colRef.withConverter(converter<OrgBalanceShard>());
    }
    case orgBalanceTransactions: {
      return colRef.withConverter(converter<OrgBalanceTransaction>());
    }
    case orgTransfers: {
      return colRef.withConverter(converter<OrgTransfer>());
    }
    case pushNotifications: {
      return colRef.withConverter(converter<PushNotification>());
    }
    case configureOffers: {
      return colRef.withConverter(converter<ConfigureOffer>());
    }
    case banners: {
      return colRef.withConverter(converter<Banner>());
    }
    case referrals: {
      return colRef.withConverter(converter<Referral>());
    }
    default: {
      throw new Error(`unhandled collection: ${colName}`);
    }
  }
}

// =================-----------------------------=====================
// =================     GET COLLECTION GROUP    =====================
// =================-----------------------------=====================

export const colGroupNames = {
  balanceTransactionsV2: "balanceTransactionsV2",
  groupTournaments: "groupTournaments",
  groupTournamentsV3: "groupTournamentsV3",
  blitzTournamentsV2: "blitzTournamentsV2",
  orgBalanceTransactions: "orgBalanceTransactions",
} as const;

type ColGroupNames = keyof typeof colGroupNames;

type ColGroups = typeof colGroupNames;

/* Fn Overloads */

/**
 * Fetch `balanceTransactionsV2`
 *
 * @param {string} colGroupName firestore collection group name
 * @return {Query<BalanceTransactionV2>} `Query<BalanceTransaction>` instance
 */
export function getCollectionGroup(
  colGroupName: ColGroups["balanceTransactionsV2"]
): Query<BalanceTransactionV2>;

/**
 * Fetch `blitzTournamentsV2`
 *
 * @param {string} colGroupName firestore collection group name
 * @return {Query<BlitzTournamentV2>} `Query<BlitzTournamentV2>` instance
 */
export function getCollectionGroup(
  colGroupName: ColGroups["blitzTournamentsV2"]
): Query<BlitzTournamentV2>;

/**
 * Fetch `groupTournaments`
 *
 * @param {string} colGroupName firestore collection group name
 * @return {Query<AsyncGroupTournamentV1>} `Query<AsyncGroupTournamentV1>` instance
 */
export function getCollectionGroup(
  colGroupName: ColGroups["groupTournaments"]
): Query<AsyncGroupTournamentV1>;

/**
 * Fetch `groupTournamentsV3`
 *
 * @param {string} colGroupName firestore collection group name
 * @return {Query<AsyncGroupTournamentV3>} `Query<AsyncGroupTournamentV3>` instance
 */
export function getCollectionGroup(
  colGroupName: ColGroups["groupTournamentsV3"]
): Query<AsyncGroupTournamentV3>;

/**
 * Fetch `groupTournaments`
 *
 * @param {string} colGroupName firestore collection group name
 * @return {Query<OrgBalanceTransaction>} `Query<OrgBalanceTransaction>` instance
 */
export function getCollectionGroup(
  colGroupName: ColGroups["orgBalanceTransactions"]
): Query<OrgBalanceTransaction>;

/* END Fn Overloads */

/**
 * Utility function to retrieve firestore collection Groups in a TYPE-SAFE way
 *
 * @param {string} colGroupName name of the Firestore collection group
 *
 * @return {Query} firestore collection group query corresponding to the `colName`
 */
export function getCollectionGroup(colGroupName: ColGroupNames): Query {
  const {
    groupTournaments,
    groupTournamentsV3,
    blitzTournamentsV2,
    orgBalanceTransactions,
    balanceTransactionsV2,
  } = colGroupNames;
  const colGroupQuery: Query = collectionGroup(db, colGroupName);
  switch (colGroupName) {
    case groupTournaments: {
      return colGroupQuery.withConverter(converter<AsyncGroupTournamentV1>());
    }
    case groupTournamentsV3: {
      return colGroupQuery.withConverter(converter<AsyncGroupTournamentV3>());
    }
    case blitzTournamentsV2: {
      return colGroupQuery.withConverter(converter<BlitzTournamentV2>());
    }
    case orgBalanceTransactions: {
      return colGroupQuery.withConverter(converter<OrgBalanceTransaction>());
    }
    case balanceTransactionsV2: {
      return colGroupQuery.withConverter(converter<BalanceTransactionV2>());
    }
  }
}

const exports = { getCollection, getCollectionGroup, colNames, colGroupNames };
export default exports;
