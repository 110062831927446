import { Collapse, Drawer, Row, Typography, notification } from "antd";
import { FC, useState } from "react";
import { FullscreenOutlined } from "@ant-design/icons";

type Props = {
  userId: string;
};

const COOKIE_BANNER_KEY = "cookie-info-banner";
export function saveCookieSettings(value: string) {
  sessionStorage.setItem(COOKIE_BANNER_KEY, value);
  return sessionStorage.getItem(COOKIE_BANNER_KEY);
}

export function getCookieSettings<T>() {
  return sessionStorage.getItem(COOKIE_BANNER_KEY) as T;
}

const HexDashboard: FC<Props> = ({ userId }) => {
  const [api, contextHolder] = notification.useNotification();
  const [open, setOpen] = useState(false);

  const [showInfo, setShowInfo] = useState(() => getCookieSettings() || "idle");
  return (
    <>
      {contextHolder}{" "}
      <Drawer
        placement="right"
        size={"large"}
        width="100vw"
        onClose={() => setOpen(false)}
        open={open}
      >
        <div
          style={{ minWidth: "100%", minHeight: "80vh" }}
          className="hex-embed"
        >
          <iframe
            title="hex-dashboard-drawer"
            width="100%"
            height="800"
            frameBorder="0"
            src={`https://app.hex.tech/2fb31ad0-1fcd-421f-b05d-ea6e0c62c82d/app/eba6a1bf-ae58-432e-b67d-eede0156d723/latest?_userID="${userId}"&embedded=true`}
          />
          <a href="https://hex.tech/?embed" target="_blank" rel="noreferrer">
            <img
              src="https://static.hex.site/embed/hex-logo-embed.png"
              alt="Hex - a modern data workspace for collaborative notebooks, data apps, dashboards, and reports."
            />
          </a>
        </div>
      </Drawer>
      <Collapse
        ghost
        destroyInactivePanel
        onChange={(key) => {
          if (key.includes("1") && showInfo === "idle") {
            saveCookieSettings("shown");
            api.info({
              message: "Enable 3rd Party Cookies to view all content!",
              description:
                "Hex dashboard needs cookies to show data on triumph website. Your browser may have disabled 3rd party cookies due to it's security policy. Please go to browser settings and enable 3rd party cookies for triumph site and refresh the page to view the content.",
              placement: "topRight",
              duration: 5,
              onClose() {
                setShowInfo("shown");
              },
            });
          }
        }}
      >
        <Collapse.Panel
          header={<Typography.Title level={5}>Hex Dashboard</Typography.Title>}
          key="1"
        >
          <Row justify="end" className="mb-2">
            <FullscreenOutlined onClick={() => setOpen(true)} />
          </Row>
          <div
            style={{ minWidth: "100%", minHeight: "80vh" }}
            className="hex-embed"
          >
            <iframe
              title="hex-dashboard"
              width="100%"
              height="800"
              frameBorder="0"
              src={`https://app.hex.tech/2fb31ad0-1fcd-421f-b05d-ea6e0c62c82d/app/eba6a1bf-ae58-432e-b67d-eede0156d723/latest?_userID="${userId}"&embedded=true`}
            />
            <a href="https://hex.tech/?embed" target="_blank" rel="noreferrer">
              <img
                src="https://static.hex.site/embed/hex-logo-embed.png"
                alt="Hex - a modern data workspace for collaborative notebooks, data apps, dashboards, and reports."
              />
            </a>
          </div>
        </Collapse.Panel>
      </Collapse>
    </>
  );
};

export default HexDashboard;
